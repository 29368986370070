<template>
  <span

      :class="{
        'text-red-600': color === 'red',
        'text-green-600': color === 'green',
        'text-orange-600': color === 'orange',
        'text-blue-600': color === 'blue',
        'text-black': color === 'black',
        'text-white': color === 'white'
      }"
  >
    <svg
        :class="{
      'animate-spin': spin,
      'inline-block': inline,
      'text-red-600': color === 'red',
      'text-green-600': color === 'green',
      'text-orange-600': color === 'orange',
      'text-blue-600': color === 'blue',
      'text-black': color === 'black',
      'text-white': color === 'white',
    }"
        class="spinner -ml-1 -mt-2 mr-3"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" :stroke="svgColor" stroke-width="4"></circle>
      <path class="opacity-75" :fill="svgColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <slot></slot>
  </span>
</template>
<script setup>
import {toRef, computed} from 'vue';
import colors from '~/src/colors';

const props = defineProps({
  inline: Boolean,
  spin: {type: Boolean, default: true},
  color: {type: String, default: 'black'},
});

const color = toRef(props, 'color');

const svgColor = computed(() => {
  if (colors[color] && colors[color][600]) {
    return colors[color][600];
  }
  return colors[color];
});
</script>
<style scoped>
.spinner {
  height: 1em;
}
</style>
